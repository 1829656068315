.blink-slow {
	animation-name: blink-slow;
	animation-duration: 0.3s;
	animation-iteration-count:infinite;
	animation-timing-function:ease-in-out;
	animation-direction: alternate;
}

@keyframes blink-slow {
	0%   { opacity: 0.4; }
	50%  { opacity: 0.7; }
	100% { opacity: 1;   }
}
