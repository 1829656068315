@keyframes fade-in-right {
  0% {
    opacity: 0;
    transform: translateX(1500px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fade-in-right {
  animation-name: fade-in-right;
}

@keyframes fade-out-right {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(1500px);
  }
}

.fade-out-right {
  animation-name: fade-out-right;
}
