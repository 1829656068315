@keyframes flip-in-y {
  0% {
    transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
  100% {
    transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }
}
.flip-in-y {
  backface-visibility: visible !important;
  animation-name: flip-in-y;
}

@keyframes flip-out-y {
  0% {
    transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }

  100% {
    transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
}
.flip-out-y {
  backface-visibility: visible !important;
  animation-name: flip-out-y;
}