.animsition {
  position:relative;
  opacity:0;
  animation-fill-mode: both;
}

// loading option
.animsition-loading {
  position:fixed;
  animation-fill-mode: both;
  opacity:0;
	position:fixed;
	top:50%;
	width:100%;
  font-size: 24px;
  text-align: center;
  .blink-slow();
  &:after {
  	content:"Loading";
    color: #aaa;
  }
}


