@keyframes flip-in-x {
  0% {
    opacity: 0;
    transform: perspective(400px) rotateX(90deg);
  }
  100% {
    opacity: 1;
    transform: perspective(400px) rotateX(0deg);
  }
}
.flip-in-x {
  animation-name: flip-in-x;
  backface-visibility: visible !important;
}

@keyframes flip-out-x {
  0% {
    opacity: 1;
    transform: perspective(400px) rotateX(0deg);
  }

  100% {
    opacity: 0;
    transform: perspective(400px) rotateX(90deg);
  }
}
.flip-out-x {
  animation-name: flip-out-x;
  backface-visibility: visible !important;
}