@keyframes fade-in {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.fade-in {
  animation-name: fade-in;
}

@keyframes fade-out {
  0% {opacity: 1;}
  100% {opacity: 0;}
}

.fade-out {
  animation-name: fade-out;
}
